<template>
    <div>
        <el-upload class="avatar-uploader" action="#" :show-file-list="false" :limit="1" :http-request="uploadAvatar"
            :before-upload="beforeUpload" :on-change="handleChange">
            <!-- <div class="image"> -->
            <!-- <el-avatar shape="square" :size="100" fit="cover" :src="avatar"></el-avatar> -->
            <!-- <img src="@/assets/user/edit.png" alt=""> -->
            <!-- <div class="modal">修改</div> -->
            <!-- 修改 -->
            <!-- </div> -->
            <div class="btnBox">修改</div>
        </el-upload>
        <ImageCropper ref="iscropper" :type="type" :uploadFileName="uploadFileName" @close="refresh"
            @uploadOssFile="uploadOssFile">
        </ImageCropper>
    </div>
</template>

<script>
import { beforeUploadMixin } from '@/mixins/BeforeUploadImageMixin';

export default {
    inject: ["reload"],
    components: {
        ImageCropper: () => import(/* webpackChunkName: "UploadAvatar" */ '@/components/ImageCropper.vue')

    },
    props: {
        avatar: {
            type: String,
            value: ''
        }
    },
    data() {
        return {
            type: 'avatarUnidentify',
            uploadFileName: '',//上传图片的名字
        }
    },
    mixins: [beforeUploadMixin],
    methods: {
        refresh() {
            this.reload();
        },
        // 获取图片本地服务器路径
        handleChange(file) {
            this.loaclServerPathIdentifi = URL.createObjectURL(file.raw)
        },

        // 主体识别生成裁剪框
        cropper() {
            this.$refs.iscropper.showModal({
                img: this.loaclServerPathIdentifi, // 裁剪图片的地址
                outputSize: 0.8, // 裁剪生成图片的质量
                outputType: 'png', // 裁剪生成图片的格式
                info: true, // 裁剪框的大小信息
                canScale: false, // 图片是否允许滚轮缩放
                autoCrop: true, // 是否默认生成截图框
                autoCropWidth: 0, // 默认生成截图框宽度
                autoCropHeight: 0, // 默认生成截图框高度
                fixedNumber: [1, 1], // 截图框的宽高比例
                fixedBox: false, // 固定截图框大小 不允许改变 
                fixed: false, // 是否开启截图框宽高固定比例
                canMove: false, // 上传图片是否可以移动
                canMoveBox: true, // 截图框能否拖动
                original: false, // 上传图片按照原始比例渲染
                centerBox: true, // 截图框是否被限制在图片里面
                infoTrue: false, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
                full: true, // 是否输出原图比例的截图
                enlarge: '1', // 图片根据截图框输出比例倍数
                mode: 'contain', // 图片默认渲染方式
                success: res => {
                    this.unimgurl = res.img
                    this.imageUrl = URL.createObjectURL(res.img)
                }
            })
        },
        //上传头像
        uploadAvatar(params) {
            this.uploadFileName = params.file.name
            this.cropper()
        },
        // 上传头像接口
        uploadOssFile(formdata) {
            let that = this;
            that.$http.uploadOssFile(formdata).then(res => {
                if (res.code == 200) {
                    that.$store.commit('saveUserAvatarUrl', res.data)
                    that.$common.message(res.msg, 'success')
                    that.$emit('refresh')
                }
            })
        }
    }
}
</script>

<style lang="less">
.avatar-uploader {
    // width: 100px;

    .el-avatar {
        border-radius: 50%;

        img {
            width: 100%;
        }
    }

    .btnBox {
        height: 40px;
        width: 120px;
        // margin-left: 40px;
        background: linear-gradient(101deg, #006EF2 0%, #33A6F9 100%);
        display: block;
        color: #fff;
        text-align: center;
        line-height: 40px;
        border-radius: 20px;
        cursor: pointer;
    }

    .image {
        // height: 40px;
        // width: 120px;
        // // margin-left: 40px;
        // background: linear-gradient(101deg, #006EF2 0%, #33A6F9 100%);
        // display: block;
        // color: #fff;
        // text-align: center;
        // line-height: 40px;
        // border-radius: 20px;
        // cursor: pointer;
        // width: 80px;
        // height: 100px;
        position: relative;

        .modal {
            height: 40px;
            width: 120px;
            // margin-left: 40px;
            background: linear-gradient(101deg, #006EF2 0%, #33A6F9 100%);
            display: block;
            color: #fff;
            text-align: center;
            line-height: 40px;
            border-radius: 20px;
            cursor: pointer;
        }
    }
}
</style>